.poster-container {
  width: 303px;
  height: 277px;
  border-radius: 15px;
  cursor: pointer;
  perspective: 600;
  position: relative;
  margin: 25px;
}
.poster {
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
  transition: all 3s ease-in-out;
  width: 85%;
  background: linear-gradient(
    48deg,
    rgba(0, 0, 0, 0.75) 0,
    rgba(0, 0, 0, 0.75) 3%,
    rgba(0, 0, 0, 0) 100%
  );
}
.flipped {
  transform: rotateY(180deg);
}
.poster .pic {
  backface-visibility: hidden;
  border-radius: 6px;
  height: 100%;
  position: absolute;
  overflow: hidden;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: white;
}
.poster .pic h2 {
  color: white;
  font-family: "Arial";
  font-size: 28px;
  margin-top: 0;
  margin: 10px 10px 10px 10px;
  font-weight: 400;
}
.poster .pic h4 {
  color: white;
  font-family: "Arial";
  margin: 10px 10px 10px 10px;
  font-size: 16px;
  font-weight: 400;
}
.poster .back {
  background: #eaeaed;
  color: #ffffff;
  line-height: 24px;
  text-align: center;
  transform: rotateY(180deg);
  font-size: 18px;
  padding-top: 50px;
  font-weight: 400;
  font-family: "Arial";
}
.servicePage .poster .back {
  padding-top: 10px;
  font-size: 16px;
  font-weight: 400;
}
.flipLearnMore {
  background-image: linear-gradient(135deg, #ffb629, #ffda56 50%, #ffd7a6);
  color: #23212a;
  border: none !important;
  font-size: 18px;
  font-weight: 500;
  position: absolute;
  bottom: 19px;
}

.flipLearnMore:hover {
  color: white !important;
  background-image: linear-gradient(
    to right,
    rgb(9, 18, 66) 0%,
    #5472d2 50%,
    #00c1cf 100%
  );
  /* background-image: linear-gradient(184deg, rgb(9, 18, 66), #23212a 50%, #23212a); */
  /* border: 1px solid white; */
}
/* Media qury for moblie and tab */
@media (max-width: 576px) {
  .padding-poster {
    margin-right: 7% !important;
  }
  .poster-container {
    width: 340px;
    height: 250px;
    border-radius: 15px;
    cursor: pointer;
    perspective: 600;
    position: relative;
    margin: 10px 0px 10px 30px;
    padding: 20px 0px;
  }
  .poster {
    width: 100%;
    /* margin-left: -1%; */
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .poster-container {
    width: 330px;
    height: 250px;
    border-radius: 15px;
    cursor: pointer;
    perspective: 600;
    position: relative;
    margin: 15px;
    padding: 20px 0px;
  }
  .poster {
    width: 100%;
  }
}
@media (min-width: 992px) and (max-width: 1024px) {
  .poster-container {
    width: 330px;
    height: 250px;
    border-radius: 15px;
    cursor: pointer;
    perspective: 600;
    position: relative;
    margin: 15px;
    padding: 20px 0px;
  }
}
@media (min-width: 300px) and (max-width: 350px) {

.poster-container {
  width: 290px !important;
}
}