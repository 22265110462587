.description-image {
  background: url(../assets/intro-image6.jpg);
  width: 510px;
  height: 268px;
  margin: 3px 10px 20px 10px;

  border-radius: 10px;
}
.recaptchatable #recaptcha_response_field {
  margin-left: -40%;
}
.recaptcha-style {
  margin-left: 40%;
}
.react-tel-input .flag-dropdown {
  border: 2px solid #898888 !important;
  border-radius: 6px;
  background-image: linear-gradient(
    135deg,
    #ffb629,
    #ffda56 50%,
    #ffd7a6
  ) !important;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background-image: linear-gradient(
    to right,
    rgb(9, 18, 66) 0%,
    #5472d2 50%,
    #00c1cf 120%
  ) !important;
}

.react-tel-input .form-control {
  width: 100% !important;
  height: 45px !important;
  border-radius: 6px !important;
}
.textmessage-style {
  margin-left: 0%;
}
.container-style {
  margin-top: -10%;
}
.multiSelectContainer li {
  font-family: "Arial";
}
.description-title {
  margin: 20px 10px 20px 10px;
  color: #000;
  font-weight: 500;
  margin-top: 15%;
  font-size: 36px;
  font-family: "Arial";
  line-height: normal;
}
.description-subtitle {
  color: #091242;
  font-weight: 500;
  font-size: 36px;
  font-family: cursive;
}
.description-paragraph {
  color: #000;
  font-weight: 400;
  font-size: 23px;
  font-family: "Arial";
  margin: 20px 10px 20px 10px;

  /* towing :Буксировка транспортных
  Безопасная и надежная доставка автомобилей и караванов во все страны мира. */
}
.description-cta {
  background-image: linear-gradient(
    90deg,
    #5472d2 50%,
    #00c1cf 120%
  ) !important;
  color: white;
  padding: 34px 25px 34px 30px;
  /* border-radius: 10px; */
  margin-top: 6%;
  margin-bottom: 4%;
  font-family: "Arial";
  text-align: center;
  height: 100px;
}

@media (max-width: 576px) {
  .description-cta {
    white-space: break-spaces;
    padding: 1px;
  }
}
.modalContainer {
  width: 113%;
  height: 100vh;
  position: absolute;
  top: -33%;
  z-index: 9999;
  left: 17%;
}

.formheader-style {
  color: black;
  text-align: center;
  margin-top: 2%;
  font-family: "Arial";
}
.close {
  background-image: linear-gradient(135deg, #ffb629, #ffda56 50%, #ffd7a6);
  border: none;
  padding: 5px 10px 5px 10px;
  border-radius: 50%;
}
.close:hover {
  background-image: linear-gradient(
    to right,
    rgb(9, 18, 66) 0%,
    #5472d2 50%,
    #00c1cf 100%
  ) !important;
}
.searchWrapper {
  background: white;

  border: 2px solid #898888 !important;
}
.modalOverlay {
  background-color: #4a4a4a;
  width: 112%;
  height: 170%;
  border-radius: 12px;
  position: absolute;
  opacity: 0.7;
}
.multiselect-style {
  margin-left: 26% !important;
}

.font-semibold {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: white !important;
}
.modalContentContainer {
  width: 40%;
  height: 83% !important;
  position: relative;
  left: 30%;
  background: rgb(255 255 255 / 75%) !important;
  top: 20%;
  border-radius: 15px;
  text-align: -webkit-center;
}
.formbackground-style {
  background: rgb(255 255 255 / 75%) !important;
  padding: 20px;
  height: 68%;
  border-radius: 5px;
}
.error-input {
  border-color: red;
  background-color: #fee;
}

::placeholder {
  /* color: black !important; */
  font-size: 14px !important;
  font-weight: 600 !important;
  font-family: "Arial";
}
.highlightOption {
  background-color: black !important;
}
.closeicon-style {
  font-size: 25px !important;
  color: white !important;
}
.modal-input-style {
  margin-left: 20%;
}

.multiSelectContainer li:hover {
  background-image: linear-gradient(
    to right,
    rgb(9, 18, 66) 0%,
    #5472d2 50%,
    #00c1cf 100%
  ) !important;
  color: #fff;
  cursor: pointer;
}

/* media Qurey  */
@media (min-width: 300px) and (max-width: 400px) {
  .modal-input-style2 {
    margin-left: -50%;
    margin-right: -50%;
  }
  .description-image {
    background: url(../assets/intro-image4.jpg);
    width: 100%;
    height: 268px;
    margin: 3px 10px 20px 1px;

    border-radius: 10px;
  }
  .recaptcha-style {
    margin-left: 4%;
  }
  .searchWrapper {
    width: 176px;
  }
  .button-jio {
    margin-left: 26% !important;
  }
  .modal-input-style {
    margin-left: 0% !important;
  }
  .modelTop-2 {
    margin-top: 6%;
  }
  .description-cta {
    margin-top: 5% !important;
  }

  .textmessage-style {
    width: 100%;
    margin-left: 1%;
  }
  .modalContentContainer {
    width: 350px;
    height: 990px !important;
    left: 2% !important;
    top: 10%;
    background-size: cover;
  }
  .modelTop {
    margin-top: 10px;
  }

  .modalContainer {
    width: 100% !important;
    height: 120vh;
    position: absolute;
    top: 0;
    z-index: 9999;
  }
  .modalOverlay {
    height: 160%;
  }
  .capha-padding-style {
    margin-left: 34%;
  }
  .modalOverlay {
    background-color: #4a4a4a;
    width: 129%;
    height: 120%;
    border-radius: 12px;
    position: absolute;
    opacity: 0.7;
  }
}
@media (min-width: 400px) and (max-width: 500px) {
  .modal-input-style2 {
    margin-left: -50%;
    margin-right: -50%;
  }
  .modal-input-style {
    margin-left: 0% !important;
  }
  .modelTop-2 {
    margin-top: 6%;
  }
  .textmessage-style {
    width: 100%;
    margin-left: 0%;
  }
  .modalContentContainer {
    width: 350px;
    height: 990px !important;
    left: 8%;
    top: 10%;
    background-size: cover;
  }
  .button-jio {
    margin-left: 29% !important;
  }
  .description-image {
    background: url(../assets/intro-image4.jpg);
    width: 100%;
    height: 268px;
    margin: 3px 10px 20px -1px;
    background-size: cover;
    border-radius: 10px;
  }
  .modelTop {
    margin-top: 10px;
  }

  .modalContainer {
    width: 100% !important;
    height: 120vh;
    position: absolute;
    top: 0;
    z-index: 9999;
  }
  .description-cta {
    margin-top: 5% !important;
  }
  .modalOverlay {
    height: 160%;
  }
  .recaptcha-style {
    margin-left: 11%;
  }
  .capha-padding-style {
    margin-left: 34%;
  }
  .modalOverlay {
    background-color: #4a4a4a;
    width: 129%;
    height: 120%;
    border-radius: 12px;
    position: absolute;
    opacity: 0.7;
  }
}
@media (min-width: 500px) and (max-width: 540px) {
  .description-image {
    /* background: url(../assets/ipadview.jpg); */
    width: 457px;
    height: 268px;
    margin: 3px 10px 20px 10px;

    border-radius: 10px;
  }
  .recaptcha-style {
    margin-left: 17%;
  }
}
@media (min-width: 540px) and (max-width: 600px) {
  .recaptcha-style {
    margin-left: 17%;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .modalOverlay {
    background-color: #4a4a4a;
    width: 116%;
    height: 120%;
    border-radius: 12px;
    position: absolute;
    opacity: 0.7;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .modalContentContainer {
    width: 350px;
    height: 990px !important;
    left: 3%;
    top: 10%;
    background-size: cover;
  }
  .recaptcha-style {
    margin-left: 21%;
  }
  .button-jio {
    margin-left: 44% !important;
  }
  .modalOverlay {
    background-color: #4a4a4a;
    width: 120%;
    height: 120%;
    border-radius: 12px;
    position: absolute;
    opacity: 0.7;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .description-image {
    background: url(../assets/ipadview.jpg);
    width: 657px;
    height: 268px;
    margin: 3px 10px 20px 10px;

    border-radius: 10px;
  }
  .recaptcha-style {
    margin-left: 55%;
  }
  .button-jio {
    margin-left: 38% !important;
  }
}
.button-jio {
  color: #23212a;
  text-align: center;
  font-family: "Arial";
  background-color: #ffbe34;
  padding: 11px 20px 13px;
  font-size: 20px;
  font-weight: 600;
  transition: background-color 0.2s;
  margin-top: 5%;
  margin-left: 36%;
  color: #23212a;
  text-align: center;
  background-image: linear-gradient(135deg, #ffb629, #ffda56 50%, #ffd7a6);
  border-radius: 10px;
}
.button-jio:hover {
  color: white !important;
  background-image: linear-gradient(
    to right,
    rgb(9, 18, 66) 0%,
    #5472d2 50%,
    #00c1cf 100%
  );
}
.formborder-style {
  /* border: 2px solid #00a0d2; */
  background: #dddddd;
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
}
@media (min-width: 992px) and (max-width: 1024px) {
  .description-image {
    background: url(../assets/intro-image6.jpg);
    width: 427px;
    height: 268px;
    margin: 3px 10px 20px 10px;

    border-radius: 10px;
  }
  .button-jio {
    margin-left: 35% !important;
  }
}
