.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.whyContainer {
  /* background: white; */
  position: relative;
  /* margin-left: 5%; */
  top: 5%;
  padding: 50px 117px 50px 117px;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.block-7lq {
  max-width: 100%;
  display: inline-block;
}
.nav-tce {
  background-image: linear-gradient(
    to right,
    rgb(9, 18, 66) 0%,
    #5472d2 50%,
    #00c1cf 120%
  ) !important;
}
@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap");
body {
  font-family: "Roboto";
  font-size: 16px;
  line-height: 1.5em;
  color: #000000;
}
.servicesContianer {
  background-color: #091242;
}
.servicesTitle1 {
  font-size: 32px;
  font-weight: 700;
  font-family: "Arial";
  margin-left: 1%;
}
.servicesContainer {
  max-width: 1120px;
  margin: 0 auto;
}
.overflow-wxy {
  overflow: hidden;
}
.container-kvh {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
}
.subtitle-a6c {
  text-align: center;
  background-color: rgba(232, 232, 232, 0.6);
  padding: 5px 10px 5px 14px;
  display: inline-block;
  position: relative;
}
.subtitle-qto {
  color: #064671 !important;
  margin-bottom: 0;
  font-family: "Arial";
  font-size: 14px;
  font-weight: 400;
}
.subtitle-aqf {
  min-width: 4px;
  background-image: linear-gradient(to right, #ffb629, #ffda56 45%, #ffd7a6);
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: auto;
}
.section-bjn {
  text-transdiv: capitalize;
  font-size: 25px;
  line-height: 1.2;
}
.carousel {
  width: 100%;
  height: 395px;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}
.carousel .slides {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  width: 50%;
  transition: 1s ease-in-out all;
  height: 100%;
}
.testimonial-slide {
  max-width: 600px;
}
.carousel .slides .slide {
  min-width: 100%;
  min-height: 250px;
  height: auto;
}
.carouselArrow {
  margin-top: 10px;
  display: flex;
  height: 56px;
}
#style-KTGA2.style-KTGA2 {
  background-color: rgb(255, 190, 52);
  position: relative;
}
.single-slide-container1 {
  /* margin-top: -120px; */
  overflow: hidden;
  position: relative;
}
@media (max-width: 576px) {
  .servicesTitle1 {
    font-size: 25px;
    font-family: cursive;
    margin-left: 5%;
  }
}
@media screen and (min-width: 1280px) {
  .container-rwt {
    max-width: 1200px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.overflow-wxy {
  overflow: hidden;
}
.container-kvh {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
}
.carouselArrow > div {
  display: flex;
}
@media screen and (min-width: 1280px) {
  .row-va2 {
    top: 10px;
    bottom: auto;
    left: auto;
    right: 1%;
  }
}
.row-va2 {
  max-height: 45px;
  max-width: 45px;
  min-height: 45px;
  min-width: 45px;
  background-color: #ffbe34;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  /* display: flex; */
  top: auto;
  bottom: 0%;
  left: -10%;
  right: 0%;
}
#style-YArlP.style-YArlP {
  background-color: rgb(244, 244, 244);
  height: 100%;
}
.card-aqx {
  background-color: #f4f4f4;
  padding: 60px;
}
.testimonial-top-ni8 {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  display: flex;
}
.testimonial-author-8jm {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  align-items: center;
  display: flex;
}
.icon-ehy {
  max-height: 70px;
  max-width: 70px;
  min-height: 70px;
  min-width: 70px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.yellow-ovm {
  background-image: linear-gradient(135deg, #ffb629, #ffda56 50%, #ffd7a6);
}
.person_detail {
  color: rgb(102, 108, 137) !important;
  font-family: "Arial";
  font-size: 18px;
  font-weight: 500;
}
#style-WGtil.style-WGtil {
  background-color: rgb(9, 18, 66);
  height: 100%;
}
.row-va2 {
  max-height: 45px;
  max-width: 45px;
  min-height: 45px;
  min-width: 45px;
  background-color: #ffbe34;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  /* display: flex; */
  top: auto;
  bottom: 0%;
  left: -10%;
  right: 0%;
}
@media screen and (min-width: 1280px) {
  .row-va2 {
    top: 10px !important;
    bottom: auto !important;
    left: auto !important;
    right: 1% !important;
  }
}
.slide-hi8 {
  width: 80px;
  cursor: pointer;
  /* color: #fff; */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  user-select: none;
  /* margin: auto; */
  font-size: 40px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: 3;
  right: auto;
}
.hid-fp1 {
  display: none;
}
.slide-8tf {
  width: 1em;
  height: 1em;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
#style-iXr2r.style-iXr2r {
  background-color: rgb(255, 190, 52);
  position: relative;
}
.carouselArrow > div {
  display: flex;
}
@media screen and (min-width: 1280px) {
  .row-v6h {
    top: 10px !important;
    bottom: auto;
    left: auto;
    right: 0%;
  }
}
.row-v6h {
  max-height: 45px;
  max-width: 45px;
  min-height: 45px;
  min-width: 45px;
  background-color: #ffbe34;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: -10%;
}
.slide-y5b {
  width: 80px;
  cursor: pointer;
  color: #fff;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  user-select: none;
  /* margin: auto; */
  font-size: 40px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: 4;
  left: auto;
}
.slide-l4v {
  width: 1em;
  height: 1em;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.hid-fp1 {
  display: none;
}
#style-xOlIt.style-xOlIt {
  filter: invert(0%);
}
#style-vnoDw.style-vnoDw {
  filter: invert(0%);
}
.row-a78.row-gfz,
.row-a78.row-2o3 {
  width: 100%;
  max-width: 100% !important;
}
.author-jl3 {
  margin-top: 0;
  margin-bottom: 5px;
  font-family: "Arial";
  font-size: 20px;
  color: rgb(28, 31, 53);
}
#style-LC6Og.style-LC6Og {
  color: rgb(255, 255, 255) !important;
}
.author-jl3 {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 20px;
  color: rgb(28, 31, 53);
}
#style-7p6Sv.style-7p6Sv {
  color: rgb(255, 255, 255) !important;
}
.author-j5t {
  color: #091242;
  font-family: "Arial";
  margin-bottom: 0;
}
#style-aXEXt.style-aXEXt {
  color: rgb(232, 232, 232) !important;
  font-family: "Arial";
  font-size: 18px;
  font-weight: 500;
}
@media (max-width: 400px) {
  .section-kt1 {
    padding-top: 0px;
    padding-bottom: 100px;
    overflow: hidden;
    margin: 0px 0px 0px 0px;
  }
  .carousel .slides {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: flex;
    width: 100%;
    transition: 1s ease-in-out all;
    height: 100%;
  }
  .container-rwt {
    max-width: 338px;
    padding-left: 36px;
    padding-right: -3px;
  }
  .card-aqx {
    padding: 20px;
  }
  .row-va2 {
    top: 1px;
    bottom: auto;
    left: auto;
    right: 3%;
  }
}
@media (min-width: 400px) and (max-width: 576px) {
  .section-kt1 {
    padding-top: 100px;
    padding-bottom: 100px;
    overflow: hidden;
    margin: -34px 0px 0px 0px;
  }
  .row-va2 {
    top: 1px;
    bottom: auto;
    left: auto;
    right: 3%;
  }
  .carousel .slides {
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .container-rwt {
    max-width: 383px;
    padding-left: 36px;
    padding-right: -3px;
  }
  .card-aqx {
    padding: 30px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .carousel .slides {
    width: 100%;
    height: 100%;
  }
  .row-va2 {
    top: 1px;
    bottom: auto;
    left: auto;
    right: 3%;
  }
  .container-rwt {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .card-aqx {
    padding: 30px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .carousel .slides {
    width: 100%;
    height: 100%;
  }
  .row-va2 {
    top: 1px;
    bottom: auto;
    left: auto;
    right: 3%;
  }
  .container-rwt {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .card-aqx {
    padding: 30px;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .carousel .slides {
    width: 50%;
    height: 120%;
  }
  .container-rwt {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .card-aqx {
    padding: 70px;
  }
}
.border-style {
  border: 2px solid #898888 !important;
}

.section-rx5 {
  text-transdiv: capitalize;
  font-size: 35px;
  font-family: "Arial";
  color: black;
}
p {
  color: black;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  /* color: #ffffff !important; */
}
#style-Fhg5H.style-Fhg5H {
  opacity: 1;
  transdiv: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transdiv-style: preserve-3d;
}
.list-o6k {
  grid-column-gap: 15px;
  align-items: center;
  display: flex;
}
.icon-qg7 {
  max-height: 65px;
  max-width: 65px;
  min-height: 65px;
  min-width: 65px;
  background-image: linear-gradient(135deg, #ffb629, #ffda56 50%, #ffd7a6);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.about-ywj {
  font-size: 17px;
  font-weight: 500;
}
@media (min-width: 300px) and (max-width: 370px) {
  .flex {
    display: block;
  }
}
@media (min-width: 300px) and (max-width: 345px) {
  .navbar-toggler-icon {
    display: block !important;
    width: 0.9em !important;
  }
}
@media (min-width: 300px) and (max-width: 333px) {
  .navbar-toggler-icon {
    display: block !important;
    width: 0.5em !important;
  }
}