.shipping-title {
  color: #000;
  font-size: 34px;
  margin-top: 20px;
  line-height: normal;
  font-weight: 500;
  font-family: "Arial";
  margin-left: 5%;
}
.shipping-image {
  background: url(../assets/personal-car-shipping.jpg);
  width: 275px;
  height: 250px;
  border-radius: 10px;
}
.shipping-image1 {
  background: url(../assets/classic-car-shipping.jpg);
  width: 275px;
  height: 250px;
  border-radius: 10px;
}
.shipping-image2 {
  background: url(../assets/bulk-car-roro-shipping.jpg);
  width: 275px;
  height: 250px;
  border-radius: 10px;
}
.shipping-image3 {
  background: url(../assets/bulk-auction-car-shipping.jpg);
  width: 275px;
  height: 250px;
  border-radius: 10px;
}
.shipping-image4 {
  background: url(../assets/personal-motorbike-shipping.jpg);
  width: 275px;
  height: 250px;
  border-radius: 10px;
}
.shipping-image5 {
  background: url(../assets/bulk-motorbike-shipping.jpg);
  width: 275px;
  height: 250px;
  border-radius: 10px;
}
.image-title {
  color: #000;
  font-size: 18px;
  font-family: "Arial";
  margin: 3% 1% 3% 16%;
}
.image-title1 {
  color: #000;
  font-size: 18px;
  font-family: "Arial";
  margin: 3% 5% 3% 7%;
}
.image-title2 {
  color: #000;
  font-size: 18px;
  font-family: "Arial";
  margin: 3% 5% 3% 12%;
}
.image-title3 {
  color: #000;
  font-size: 18px;
  font-family: "Arial";
  margin: 3% 5% 3% -4%;
}
.image-title4 {
  color: #000;
  font-size: 18px;
  font-family: "Arial";
  margin: 3% 5% 3% 17%;
}
.image-title5 {
  color: #000;
  font-size: 18px;
  font-family: "Arial";
  margin: 3% 15% 3% 14%;
}
.shipping-style {
  background: url(../assets/bg-img4.jpg);
}
.background-style {
  background: rgb(255 255 255 / 0.9) !important;
  padding: 20px;
  filter: opacity(150%);
  height: 68%;
  border-radius: 5px;
}
.row-style {
  padding: 10px 6px 10px 60px;
}
@media (min-width: 300px) and (max-width: 400px) {
  .row-style {
    padding: 10px 39px 10px 39px;
  }
  .shipping-title {
    margin-left: 11%;
  }
  .image-title3 {
    margin: 3% 5% 3% 14%;
  }
}
@media (min-width: 400px) and (max-width: 576px) {
  .shipping-title {
    margin-left: 8%;
  }
  .image-title3 {
    margin: 3% 25% 3% 8%;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .row-style {
    padding: 10px 36px 10px 39px;
  }
}
@media (min-width: 992px) and (max-width: 1024px) {
  .image-title3 {
    color: #000;
    font-size: 18px;
    font-family: "Arial";
    margin: 3% 5% 3% 10%;
  }
}
