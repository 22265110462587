.single-slide-container {
  height: 600px;
  overflow: hidden;
  position: relative;
}
.title-c6g {
  max-width: 550px;
}

.slideText3 {
  position: absolute !important;
  top: 35%;
  left: 124px;
  padding: 20px;
  z-index: 1;
}
.subtitle-wrap-dark {
  background-color: rgba(255, 255, 255, 0.6);
  padding: 5px 10px 5px 14px;
  display: inline-block;
  position: relative;
}
.subtitle-border {
  min-width: 4px;
  background-image: linear-gradient(to right, #ffb629, #ffda56 45%, #ffd7a6);
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: auto;
}
.subtitle-dark {
  color: #064671 !important;
  margin-bottom: 0;
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-weight: 400;
  opacity: 2.5;
}
.page-title {
  color: #fff;
  margin-top: -15%;
  margin-bottom: 10px;
  font-size: 40px;
  line-height: 1.2;
  font-weight: 700;
}
.subpage-title {
  color: #fff;
  margin-top: -18%;
  margin-bottom: 10px;
  font-family: "Arial";
  font-size: 28px;
  line-height: 1.2;
  font-weight: 700;
}
.banner-image {
  background: url(../assets/auto-shipping-banner.jpg);
  width: 1600px;
  top: 0;
  left: 0;
  height: 493px;
  /* position: relative; */
  z-index: 1;
}
.icon-images {
  background: url(../assets/motor-homes.jpg);
  width: 150px;
  height: 150px;
  position: absolute !important;
  /* margin-top: -17%; */
  inset: 80% 0% 0% 10%;
  z-index: 2;
  /* margin-left: 5%; */
  border-radius: 90px;
  border: 3px solid white;
}
.icon-images1 {
  background: url(../assets/motorcycle.jpg);
  width: 150px;
  height: 150px;
  position: absolute !important;
  /* margin-top: -17%; */
  inset: 80% 0% 0% 23%;
  z-index: 2;
  /* margin-left: 5%; */
  border-radius: 90px;
  border: 3px solid white;
}
.icon-images2 {
  background: url(../assets/jetskis.jpg);
  width: 150px;
  height: 150px;
  position: absolute !important;
  /* margin-top: -17%; */
  inset: 80% 0% 0% 36%;
  z-index: 2;
  /* margin-left: 5%; */
  border-radius: 90px;
  border: 3px solid white;
}
.icon-images3 {
  background: url(../assets/car.jpg);
  width: 150px;
  height: 150px;
  position: absolute !important;
  /* margin-top: -17%; */
  inset: 80% 0% 0% 49%;
  z-index: 2;
  /* margin-left: 5%; */
  border-radius: 90px;
  border: 3px solid white;
}
.w-full {
  position: relative;
  top: 0;
  left: 0;
}
.imagetext-style {
  color: #00a0d2;
  position: absolute !important;
  margin-top: 110%;
  font-family: "Arial";
  font-size: 23px;
  margin-left: 12%;
}
.imagetext-style1 {
  color: #00a0d2;
  position: absolute !important;
  margin-top: 110%;
  font-family: "Arial";
  font-size: 23px;
  margin-left: 9%;
}
.imagetext-style2 {
  color: #00a0d2;
  position: absolute !important;
  margin-top: 110%;
  margin-left: 9%;
  font-family: "Arial";
  font-size: 23px;
}
.imagetext-style3 {
  color: #00a0d2;
  position: absolute !important;
  margin-top: 110%;
  font-family: "Arial";
  margin-left: 9%;
  font-size: 23px;
}
.imagetext-style41 {
  font-family: "Arial";
}
.imagetext-style42 {
  font-family: "Arial";
}
.imagetext-style51 {
  font-family: "Arial";
}
.imagetext-style52 {
  font-family: "Arial";
}
.imagetext-style61 {
  font-family: "Arial";
}
.imagetext-style62 {
  font-family: "Arial";
}
.imagetext-style71 {
  font-family: "Arial";
}
.imagetext-style72 {
  font-family: "Arial";
}
@media (min-width: 300px) and (max-width: 400px) {
  .banner-image {
    height: 493px !important;
    width: 390px;
    /* background: url(../assets/banner-mobileView.jpg); */
  }
  .imagetext-style {
    /* color: #00a0d2;
    position: absolute !important;
    margin-top: 110%;
    font-size: 16px;
    margin-left: 1%; */
    display: none;
  }
  .imagetext-style1 {
    /* color: #00a0d2;
    position: absolute !important;
    margin-top: 110%;
    font-size: 16px;
    margin-left: -13%; */
    display: none;
  }
  .imagetext-style2 {
    /* color: #00a0d2;
    position: absolute !important;
    margin-top: 110%;
    font-size: 16px;
    margin-left: -16%; */
    display: none;
  }
  .imagetext-style3 {
    /* color: #00a0d2;
    position: absolute !important;
    margin-top: 110%;
    font-size: 16px;
    margin-left: -16%; */
    display: none;
  }
  .imagetext-style41 {
    color: #00a0d2;
    position: absolute !important;
    margin-top: 110%;
    font-size: 16px;
    margin-left: 25%;
  }
  .imagetext-style42 {
    color: #00a0d2;
    position: absolute !important;
    margin-top: 131%;
    font-size: 16px;
    margin-left: 20%;
  }
  .imagetext-style51 {
    color: #00a0d2;
    position: absolute !important;
    margin-top: 110%;
    font-size: 16px;
    margin-left: 25%;
  }
  .imagetext-style52 {
    color: #00a0d2;
    position: absolute !important;
    margin-top: 131%;
    font-size: 16px;
    margin-left: 20%;
  }
  .imagetext-style61 {
    color: #00a0d2;
    position: absolute !important;
    margin-top: 110%;
    font-size: 16px;
    margin-left: 25%;
  }
  .imagetext-style62 {
    color: #00a0d2;
    position: absolute !important;
    margin-top: 131%;
    font-size: 16px;
    margin-left: 20%;
  }
  .imagetext-style71 {
    color: #00a0d2;
    position: absolute !important;
    margin-top: 110%;
    font-size: 16px;
    margin-left: 25%;
  }
  .imagetext-style72 {
    color: #00a0d2;
    position: absolute !important;
    margin-top: 131%;
    font-size: 16px;
    margin-left: 20%;
  }

  .page-title {
    color: #fff;
    margin-top: -40%;
    margin-bottom: 10px;
    margin-left: -58%;
    font-size: 30px;
    line-height: 1.2;
    font-weight: 500;
  }
  .subpage-title {
    color: #fff;
    margin-top: -83%;
    margin-left: -60%;
    margin-bottom: 10px;
    font-size: 25px;
    line-height: 1.2;
    font-weight: 500;
  }
  .icon-images {
    background: url(../assets/motor-homes-mobile.jpg);
    width: 90px;
    height: 90px;
    position: absolute !important;
    /* margin-top: -17%; */
    inset: 87% 0% 0% 1%;
    z-index: 2;
    /* margin-left: 5%; */
    border-radius: 90px;
    border: 3px solid white;
  }
  .icon-images1 {
    background: url(../assets/motorcycle-mobile.jpg);
    width: 90px;
    height: 90px;
    position: absolute !important;
    /* margin-top: -17%; */
    inset: 87% 0% 0% 26%;
    z-index: 2;
    /* margin-left: 5%; */
    border-radius: 90px;
    border: 3px solid white;
  }
  .icon-images2 {
    background: url(../assets/jetskis-mobile.jpg);
    width: 90px;
    height: 90px;
    position: absolute !important;
    /* margin-top: -17%; */
    inset: 87% 0% 0% 51%;
    z-index: 2;
    /* margin-left: 5%; */
    border-radius: 90px;
    border: 3px solid white;
  }
  .icon-images3 {
    background: url(../assets/car-mobile.jpg);
    width: 90px;
    height: 90px;
    position: absolute !important;
    /* margin-top: -17%; */
    inset: 87% 0% 0% 76%;
    z-index: 2;
    /* margin-left: 5%; */
    border-radius: 90px;
    border: 3px solid white;
  }
}
@media (min-width: 400px) and (max-width: 500px) {
  .banner-image {
    height: 493px !important;
    width: 511px;
    /* background: url(../assets/banner-mobileView.jpg); */
  }

  .imagetext-style {
    /* color: #00a0d2;
    position: absolute !important;
    margin-top: 108%;
    font-size: 18px;
    margin-left: -12%;
    white-space: nowrap; */
    display: none;
  }
  .imagetext-style1 {
    /* color: #00a0d2;
    position: absolute !important;
    margin-top: 108%;
    font-size: 18px;
    margin-left: -13%; */
    display: none;
  }
  .imagetext-style2 {
    /* color: #00a0d2;
    position: absolute !important;
    margin-top: 108%;
    font-size: 18px;
    margin-left: -18%; */
    display: none;
  }
  .imagetext-style3 {
    /* color: #00a0d2;
    position: absolute !important;
    margin-top: 108%;
    font-size: 18px;
    margin-left: -19%; */
    display: none;
  }
  .imagetext-style41 {
    color: #00a0d2;
    position: absolute !important;
    margin-top: 108%;
    font-size: 18px;
    margin-left: 20%;
  }
  .imagetext-style42 {
    color: #00a0d2;
    position: absolute !important;
    margin-top: 130%;
    font-size: 18px;
    margin-left: 20%;
  }
  .imagetext-style51 {
    color: #00a0d2;
    position: absolute !important;
    margin-top: 108%;
    font-size: 18px;
    margin-left: 20%;
  }
  .imagetext-style52 {
    color: #00a0d2;
    position: absolute !important;
    margin-top: 130%;
    font-size: 18px;
    margin-left: 20%;
  }
  .imagetext-style61 {
    color: #00a0d2;
    position: absolute !important;
    margin-top: 108%;
    font-size: 18px;
    margin-left: 20%;
  }
  .imagetext-style62 {
    color: #00a0d2;
    position: absolute !important;
    margin-top: 130%;
    font-size: 18px;
    margin-left: 20%;
  }
  .imagetext-style71 {
    color: #00a0d2;
    position: absolute !important;
    margin-top: 108%;
    font-size: 18px;
    margin-left: 20%;
  }
  .imagetext-style72 {
    color: #00a0d2;
    position: absolute !important;
    margin-top: 130%;
    font-size: 18px;
    margin-left: 20%;
  }
  .icon-images {
    background: url(../assets/motor-homes-mobile.jpg);
    width: 90px;
    height: 90px;
    position: absolute !important;
    /* margin-top: -17%; */
    inset: 87% 0% 0% 5%;
    z-index: 2;
    /* margin-left: 5%; */
    border-radius: 90px;
    border: 3px solid white;
  }
  .icon-images1 {
    background: url(../assets/motorcycle-mobile.jpg);
    width: 90px;
    height: 90px;
    position: absolute !important;
    /* margin-top: -17%; */
    inset: 87% 0% 0% 29%;
    z-index: 2;
    /* margin-left: 5%; */
    border-radius: 90px;
    border: 3px solid white;
  }
  .icon-images2 {
    background: url(../assets/jetskis-mobile.jpg);
    width: 90px;
    height: 90px;
    position: absolute !important;
    /* margin-top: -17%; */
    inset: 87% 0% 0% 53%;
    z-index: 2;
    /* margin-left: 5%; */
    border-radius: 90px;
    border: 3px solid white;
  }
  .icon-images3 {
    background: url(../assets/car-mobile.jpg);
    width: 90px;
    height: 90px;
    position: absolute !important;
    /* margin-top: -17%; */
    inset: 87% 0% 0% 77%;
    z-index: 2;
    /* margin-left: 5%; */
    border-radius: 90px;
    border: 3px solid white;
  }
  .page-title {
    color: #fff;
    margin-top: -40%;
    margin-bottom: 10px;
    margin-left: -44%;
    font-size: 30px;
    line-height: 1.2;
    font-weight: 500;
  }
  .subpage-title {
    color: #fff;
    margin-top: -67%;
    margin-left: -34%;
    margin-bottom: 10px;
    font-size: 25px;
    line-height: 1.2;
    font-weight: 500;
  }
}
@media (min-width: 540px) and (max-width: 600px) {
  .banner-image {
    height: 493px !important;
    width: 600px;
    /* background: url(../assets/surfaceDuoMobile.jpg); */
  }
  .page-title {
    color: #fff;
    margin-top: -40%;
    margin-bottom: 10px;
    margin-left: -14%;
    font-size: 42px;
    line-height: 1.2;
    font-weight: 500;
  }
  .subpage-title {
    color: #fff;
    margin-top: -30%;
    margin-left: -24%;
    margin-bottom: 10px;
    font-size: 25px;
    line-height: 1.2;
    font-weight: 500;
  }
  .imagetext-style {
    /* color: #00a0d2;
    position: absolute !important;
    margin-top: 108%;
    font-size: 18px;
    margin-left: -12%;
    white-space: nowrap; */
    display: none;
  }
  .imagetext-style1 {
    /* color: #00a0d2;
    position: absolute !important;
    margin-top: 108%;
    font-size: 18px;
    margin-left: -13%; */
    display: none;
  }
  .imagetext-style2 {
    /* color: #00a0d2;
    position: absolute !important;
    margin-top: 108%;
    font-size: 18px;
    margin-left: -18%; */
    display: none;
  }
  .imagetext-style3 {
    /* color: #00a0d2;
    position: absolute !important;
    margin-top: 108%;
    font-size: 18px;
    margin-left: -19%; */
    display: none;
  }
  .imagetext-style41 {
    color: #00a0d2;
    position: absolute !important;
    margin-top: 108%;
    font-size: 18px;
    margin-left: 20%;
  }
  .imagetext-style42 {
    color: #00a0d2;
    position: absolute !important;
    margin-top: 130%;
    font-size: 18px;
    margin-left: 20%;
  }
  .imagetext-style51 {
    color: #00a0d2;
    position: absolute !important;
    margin-top: 108%;
    font-size: 18px;
    margin-left: 20%;
  }
  .imagetext-style52 {
    color: #00a0d2;
    position: absolute !important;
    margin-top: 130%;
    font-size: 18px;
    margin-left: 20%;
  }
  .imagetext-style61 {
    color: #00a0d2;
    position: absolute !important;
    margin-top: 108%;
    font-size: 18px;
    margin-left: 20%;
  }
  .imagetext-style62 {
    color: #00a0d2;
    position: absolute !important;
    margin-top: 130%;
    font-size: 18px;
    margin-left: 20%;
  }
  .imagetext-style71 {
    color: #00a0d2;
    position: absolute !important;
    margin-top: 108%;
    font-size: 18px;
    margin-left: 20%;
  }
  .imagetext-style72 {
    color: #00a0d2;
    position: absolute !important;
    margin-top: 130%;
    font-size: 18px;
    margin-left: 20%;
  }
  .icon-images {
    background: url(../assets/motor-homes-mobile.jpg);
    width: 90px;
    height: 90px;
    position: absolute !important;
    /* margin-top: -17%; */
    inset: 87% 0% 0% 5%;
    z-index: 2;
    /* margin-left: 5%; */
    border-radius: 90px;
    border: 3px solid white;
  }
  .icon-images1 {
    background: url(../assets/motorcycle-mobile.jpg);
    width: 90px;
    height: 90px;
    position: absolute !important;
    /* margin-top: -17%; */
    inset: 87% 0% 0% 29%;
    z-index: 2;
    /* margin-left: 5%; */
    border-radius: 90px;
    border: 3px solid white;
  }
  .icon-images2 {
    background: url(../assets/jetskis-mobile.jpg);
    width: 90px;
    height: 90px;
    position: absolute !important;
    /* margin-top: -17%; */
    inset: 87% 0% 0% 53%;
    z-index: 2;
    /* margin-left: 5%; */
    border-radius: 90px;
    border: 3px solid white;
  }
  .icon-images3 {
    background: url(../assets/car-mobile.jpg);
    width: 90px;
    height: 90px;
    position: absolute !important;
    /* margin-top: -17%; */
    inset: 87% 0% 0% 77%;
    z-index: 2;
    /* margin-left: 5%; */
    border-radius: 90px;
    border: 3px solid white;
  }
}
@media (min-width: 500px) and (max-width: 540px) {
  .banner-image {
    height: 493px !important;
    width: 600px;
    /* background: url(../assets/surfaceDuoMobile.jpg); */
  }
  .page-title {
    color: #fff;
    margin-top: -40%;
    margin-bottom: 10px;
    margin-left: -14%;
    font-size: 42px;
    line-height: 1.2;
    font-weight: 500;
  }
  .subpage-title {
    color: #fff;
    margin-top: -30%;
    margin-left: -24%;
    margin-bottom: 10px;
    font-size: 25px;
    line-height: 1.2;
    font-weight: 500;
  }
  .imagetext-style {
    /* color: #00a0d2;
    position: absolute !important;
    margin-top: 108%;
    font-size: 18px;
    margin-left: -12%;
    white-space: nowrap; */
    display: none;
  }
  .imagetext-style1 {
    /* color: #00a0d2;
    position: absolute !important;
    margin-top: 108%;
    font-size: 18px;
    margin-left: -13%; */
    display: none;
  }
  .imagetext-style2 {
    /* color: #00a0d2;
    position: absolute !important;
    margin-top: 108%;
    font-size: 18px;
    margin-left: -18%; */
    display: none;
  }
  .imagetext-style3 {
    /* color: #00a0d2;
    position: absolute !important;
    margin-top: 108%;
    font-size: 18px;
    margin-left: -19%; */
    display: none;
  }
  .imagetext-style41 {
    color: #00a0d2;
    position: absolute !important;
    margin-top: 108%;
    font-size: 18px;
    margin-left: 20%;
  }
  .imagetext-style42 {
    color: #00a0d2;
    position: absolute !important;
    margin-top: 130%;
    font-size: 18px;
    margin-left: 20%;
  }
  .imagetext-style51 {
    color: #00a0d2;
    position: absolute !important;
    margin-top: 108%;
    font-size: 18px;
    margin-left: 20%;
  }
  .imagetext-style52 {
    color: #00a0d2;
    position: absolute !important;
    margin-top: 130%;
    font-size: 18px;
    margin-left: 20%;
  }
  .imagetext-style61 {
    color: #00a0d2;
    position: absolute !important;
    margin-top: 108%;
    font-size: 18px;
    margin-left: 20%;
  }
  .imagetext-style62 {
    color: #00a0d2;
    position: absolute !important;
    margin-top: 130%;
    font-size: 18px;
    margin-left: 20%;
  }
  .imagetext-style71 {
    color: #00a0d2;
    position: absolute !important;
    margin-top: 108%;
    font-size: 18px;
    margin-left: 20%;
  }
  .imagetext-style72 {
    color: #00a0d2;
    position: absolute !important;
    margin-top: 130%;
    font-size: 18px;
    margin-left: 20%;
  }
  .icon-images {
    background: url(../assets/motor-homes-mobile.jpg);
    width: 90px;
    height: 90px;
    position: absolute !important;
    /* margin-top: -17%; */
    inset: 87% 0% 0% 5%;
    z-index: 2;
    /* margin-left: 5%; */
    border-radius: 90px;
    border: 3px solid white;
  }
  .icon-images1 {
    background: url(../assets/motorcycle-mobile.jpg);
    width: 90px;
    height: 90px;
    position: absolute !important;
    /* margin-top: -17%; */
    inset: 87% 0% 0% 29%;
    z-index: 2;
    /* margin-left: 5%; */
    border-radius: 90px;
    border: 3px solid white;
  }
  .icon-images2 {
    background: url(../assets/jetskis-mobile.jpg);
    width: 90px;
    height: 90px;
    position: absolute !important;
    /* margin-top: -17%; */
    inset: 87% 0% 0% 53%;
    z-index: 2;
    /* margin-left: 5%; */
    border-radius: 90px;
    border: 3px solid white;
  }
  .icon-images3 {
    background: url(../assets/car-mobile.jpg);
    width: 90px;
    height: 90px;
    position: absolute !important;
    /* margin-top: -17%; */
    inset: 87% 0% 0% 77%;
    z-index: 2;
    /* margin-left: 5%; */
    border-radius: 90px;
    border: 3px solid white;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .banner-image {
    height: 400px;
  }
  .icon-images {
    inset: 80% 0% 0% 4% !important;
  }
  .icon-images1 {
    inset: 80% 0% 0% 26% !important;
  }
  .icon-images2 {
    inset: 80% 0% 0% 48% !important;
  }
  .icon-images3 {
    inset: 80% 0% 0% 70% !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .icon-images {
    inset: 80% 0% 0% 2%;
  }
  .icon-images1 {
    inset: 80% 0% 0% 21%;
  }
  .icon-images2 {
    inset: 80% 0% 0% 40%;
  }
  .icon-images3 {
    inset: 80% 0% 0% 59%;
  }
}
@media (min-width: 600px) and (max-width: 4000px) {
  .imagetext-style41 {
    display: none;
  }
  .imagetext-style42 {
    display: none;
  }
  .imagetext-style51 {
    display: none;
  }
  .imagetext-style52 {
    display: none;
  }
  .imagetext-style61 {
    display: none;
  }
  .imagetext-style62 {
    display: none;
  }
  .imagetext-style71 {
    display: none;
  }
  .imagetext-style72 {
    display: none;
  }
}
