.section-kt1 {
    padding-top: 0px;
    padding-bottom: 100px;
    overflow: hidden;
    margin: -30px 110px 0px 108px;
}
@media screen and (min-width: 1280px){
    .container-rwt {
        max-width: 1200px;
        padding-left: 0;
        padding-right: 0;
    }
}
