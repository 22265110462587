body {
  font-family: Montserrat, sans-serif;
}
.block-7lq {
  max-width: 100%;
  display: inline-block;
}
.dropdown-menu.show {
  background-image: linear-gradient(135deg, #ffb629, #ffda56 50%, #ffd7a6);
}
.dropdown-item:active {
  background-image: linear-gradient(135deg, #ffb629, #ffda56 50%, #ffd7a6);
  color: black;
}

.dropdown-item:hover {
  background-image: linear-gradient(
    90deg,
    #091242 0,
    #5472d2 50%,
    #00c1cf 120%
  ) !important;
  color: white !important;
}
.dropdown-item{
  font-size: 18px !important;
  font-weight: 500 !important;
}
.nav-link {
  color: white !important;
  font-size: 20px !important;
}
.nav-link:hover {
  background-image: linear-gradient(
    90deg,
    #091242 0,
    #5472d2 50%,
    #00c1cf 120%
  ) !important;
  border-radius: 12px !important;
}
.nav-item {
  margin-left: 120%;
  margin-top: -20%;
}
.dropdown-toggle {
}
.dropdown {
  background: none !important;
}
.dropdown:hover {
}
.navbar-nav .nav-item:hover {
  background-color: rgba(180, 190, 203, 0.4);
}
.nav-8kl {
  z-index: 1000;
  background: #ddd;
  position: relative;
}
.custom-navbar {
  background-color: aqua;
}
.navheaderText-style {
  color: #ffff;
  font-size: 25px;
  font-family: "Arial";
  font-weight: 500;
}
.navheaderText-style1 {
  color: #ffff;
  font-size: 25px;
  font-weight: 500;
}
/* fixed social*/
#fixed-social {
  z-index: 1;
  position: fixed;
  top: 40%;
  margin-left: 200px;
  right: 0%;

  display: flex;
  flex-direction: column;
}

#fixed-social a {
  color: #fff;
  display: block;
  height: 40px;
  position: relative;
  text-align: center;
  line-height: 40px;
  width: 40px;
  margin-bottom: 1px;
  z-index: 2;
  margin: 5px 5px 5px 5px;
  border-radius: 50px !important;
}
#fixed-social a:hover > span {
  visibility: visible;
  left: 41px;
  opacity: 1;
}
#fixed-social a span {
  line-height: 40px;
  left: 60px;
  position: absolute;
  text-align: center;
  width: 120px;
  visibility: hidden;
  transition-duration: 0.5s;
  z-index: 1;
  opacity: 0;
}
.fixed-whatspp {
  background-color: #25d366;
  color: #fff;
}
.fixed-whatspp span {
  background-color: #25d366;
  color: #fff;
}
.fixed-facebook {
  background-color: #2c80d3;
  color: #fff;
}
.fixed-facebook span {
  background-color: #2c80d3;
  color: #fff;
}
.fixed-xtwitter {
  background-image: linear-gradient(
    to right,

    #14171a 50%,
    #f5f8fa 100%
  ) !important;

  color: #fff;
}
.fixed-xtwitter span {
  background-image: linear-gradient(
    to right,

    #14171a 50%,
    #f5f8fa 100%
  ) !important;

  color: #fff;
}
.fixed-instagram {
  background-image: linear-gradient(
    to right,
    #ee2a7b 0%,
    #f9ce34 50%,
    #6228d7 100%
  ) !important;
  color: #fff;
}
.fixed-instagram span {
  background-image: linear-gradient(
    to right,
    #ee2a7b 0%,
    #f9ce34 50%,
    #6228d7 100%
  ) !important;
  color: #fff;
}
.fixed-linkedin {
  background-color: #258dbf;
  color: #fff;
}
.fixed-linkedin span {
  background-color: #258dbf;
  color: #fff;
}
.header-style-left {
  margin-left: 30px;
}
.icons-style {
  font-size: 30px;
  margin-top: 5px;
}
@media (min-width: 300px) and (max-width: 400px) {
  #fixed-social {
    margin-right: 3%;
  }
  .nav-item {
    margin-left: 14%;
    margin-top: -10%;
  }
  .navheaderText-style {
    color: #ffff;
    font-size: 20px;
    font-weight: 500;
    white-space: nowrap;
    margin-left: 21%;
  }
  .block-7lq {
    max-width: 70%;
    display: inline-block;
  }
  .logo-style {
    width: 100%;
    margin-left: 6%;
  }
  .navheaderText-style1 {
    color: #ffff;
    font-size: 20px;
    font-weight: 500;
    margin-left: 20%;
  }
}
@media (min-width: 400px) and (max-width: 500px) {
  .navheaderText-style {
    color: #ffff;
    font-size: 22px;
    font-weight: 500;
    margin-left: 25%;
    white-space: nowrap;
  }
  .nav-item {
    margin-left: 15%;
    margin-top: -10%;
  }
  .navheaderText-style1 {
    color: #ffff;
    font-size: 22px;
    font-weight: 500;
    margin-left: 23%;
  }
  .block-7lq {
    max-width: 70%;
    display: inline-block;
  }
  .logo-style {
    width: 100%;
    margin-left: 20%;
  }
  #fixed-social {
    margin-right: 1%;
  }
}
@media (min-width: 500px) and (max-width: 540px) {
  .navheaderText-style {
    color: #ffff;
    font-size: 22px;
    font-weight: 500;
    margin-left: 47%;
    white-space: nowrap;
  }
  .navheaderText-style1 {
    color: #ffff;
    font-size: 22px;
    font-weight: 500;
    margin-left: 46%;
  }
  .nav-item {
    margin-left: 26%;
    margin-top: -9%;
  }
  .block-7lq {
    max-width: 70%;
    display: inline-block;
  }
  .logo-style {
    width: 100%;
    margin-left: 47%;
  }
}
@media (min-width: 540px) and (max-width: 570px) {
  .navheaderText-style {
    color: #ffff;
    font-size: 22px;
    font-weight: 500;
    margin-left: 52%;
    white-space: nowrap;
  }
  .navheaderText-style1 {
    color: #ffff;
    font-size: 22px;
    font-weight: 500;
    margin-left: 52%;
  }
  .nav-item {
    margin-left: 26%;
    margin-top: -9%;
  }
  .block-7lq {
    max-width: 70%;
    display: inline-block;
  }
  .logo-style {
    width: 100%;
    margin-left: 47%;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .nav-item {
    margin-left: -20%;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .nav-item {
    margin-left: -20%;
  }
}
@media (min-width: 992px) and (max-width: 1024px) {
  .nav-item {
    margin-left: 50%;
  }
}
