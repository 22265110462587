.leftContainer {
  background-color: #091242;
}
.whyImg {
  left: 60px;
  position: relative;
  top: 60px;
}
.benefits-Heading {
  font-size: 17px;
  font-weight: 500;
  font-family: "Arial";
}
.benefits-Heading1 {
  font-size: 17px;
  font-family: "Arial";
  font-weight: 500;
  margin-top: -10%;
}
.benefits-Heading2 {
  font-size: 17px;
  font-family: "Arial";
  font-weight: 500;
}
.benefits-Heading3 {
  font-size: 17px;
  font-family: "Arial";
  font-weight: 500;
}
.benefits-Heading4 {
  font-size: 17px;
  font-family: "Arial";
  font-weight: 500;
}
.benefits-Heading5 {
  font-size: 17px;
  font-family: "Arial";
  font-weight: 500;
}
.benefits-Heading6 {
  font-size: 17px;
  font-family: "Arial";
  font-weight: 500;
}
.benefits-section {
  margin-left: 7%;
}

.rightContainer {
  padding: 80px 30px 20px 100px;
}

.why-text img {
  width: 90%;
}

.left-container-text {
  position: relative;
  padding: 20px;
  width: 300px;
  background-image: linear-gradient(225deg, #ffd7a6, #ffda56 40%, #ffb629 50%);
  margin: auto;
  top: -14px;
}
.left-container-text .icon-container img {
  width: 100%;
  left: 0px;
  position: relative;
  top: 0px;
}
.left-container-text .text-container-icon {
  font-size: 20px;
  padding-left: 15px;
}
.subtitle-wrap {
  text-align: center;
  background-color: rgba(232, 232, 232, 0.6);
  padding: 5px 10px 5px 14px;
  display: inline-block;
  position: relative;
}
.subtitle {
  color: #064671 !important;
  margin-bottom: 0;
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-family: "Arial";
  font-weight: 400;
}
.roro-shipping-content {
  font-family: "Arial";
}
.benefits-paragraph {
  font-family: "Arial";
}
.subtitle-border {
  min-width: 4px;
  background-image: linear-gradient(to right, #ffb629, #ffda56 45%, #ffd7a6);
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: auto;
}

@media (min-width: 300px) and (max-width: 400px) {
  .whyImg {
    left: 0px;
    position: relative;
    top: 5px;
  }
  .Benefits-paragraph {
    font-size: 18px;
  }
  .whyContainer {
    /* background: white; */
    position: relative;
    /* margin-left: 5%; */
    top: 5%;
    padding: 50px 60px 50px 48px !important;
  }
  .icon-qg7 {
    margin-top: 10%;
  }
  .roro-shipping {
    margin-left: 32%;
  }
  .leftContainer {
    margin: 10px 18px 10px -13px !important;
  }
  .roro-shipping-content {
    font-size: 20px;
    font-weight: 400;
  }
  .leftContainer {
    background-color: #091242;
    margin: 10px 26px 10px -30px;
    width: 100% !important;
  }
  .benefits-section {
    margin-top: 4% !important;
    margin-left: -9%;
  }
  .benefits-Heading {
    font-size: 22px !important;
    margin-top: -23%;
    margin-left: 37%;
    font-weight: 500 !important;
  }
  .benefits-Heading1 {
    font-size: 22px !important;
    margin-top: -20%;
    margin-left: 37%;
    font-weight: 500 !important;
  }
  .benefits-Heading2 {
    font-size: 22px !important;
    margin-top: -24%;
    margin-left: 37%;
    font-weight: 500 !important;
  }
  .benefits-Heading3 {
    font-size: 22px !important;
    margin-top: -24%;
    margin-left: 37%;
    font-weight: 500 !important;
  }
  .benefits-Heading4 {
    font-size: 22px !important;
    margin-top: -30%;
    margin-left: 37%;
    font-weight: 500 !important;
  }
  .benefits-Heading5 {
    font-size: 22px !important;
    margin-top: -30%;
    margin-left: 37%;
    font-weight: 500 !important;
  }
}
@media (min-width: 400px) and (max-width: 576px) {
  .whyImg {
    left: 0px;
    position: relative;
    top: 5px;
  }
  .Benefits-paragraph {
    font-size: 20px;
  }
  .whyContainer {
    /* background: white; */
    position: relative;
    /* margin-left: 5%; */
    top: 5%;
    padding: 50px 55px 50px 55px !important;
  }
  .icon-qg7 {
    max-height: 90px !important;
    max-width: 90px !important;
  }
  .roro-shipping {
    margin-left: 32%;
  }
  .roro-shipping-content {
    font-size: 20px;
    font-weight: 400;
  }
  .icon-qg7 {
    margin-top: 10%;
  }
  .leftContainer {
    background-color: #091242;
    margin: 10px 20px 10px -20px;
    width: 368px !important;
  }
  .benefits-section {
    margin-top: 4% !important;
    margin-left: -9%;
  }
  .benefits-Heading {
    font-size: 22px !important;
    margin-top: -24%;
    margin-left: 37%;
    font-weight: 500 !important;
  }
  .benefits-Heading1 {
    font-size: 22px !important;
    margin-top: -28%;
    margin-left: 37%;
    font-weight: 500 !important;
  }
  .benefits-Heading2 {
    font-size: 22px !important;
    margin-top: -24%;
    margin-left: 37%;
    font-weight: 500 !important;
  }
  .benefits-Heading3 {
    font-size: 22px !important;
    margin-top: -24%;
    margin-left: 37%;
    font-weight: 500 !important;
  }
  .benefits-Heading4 {
    font-size: 22px !important;
    margin-top: -29%;
    margin-left: 37%;
    font-weight: 500 !important;
  }
  .benefits-Heading5 {
    font-size: 22px !important;
    margin-top: -29%;
    margin-left: 37%;
    font-weight: 500 !important;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .leftContainer {
    width: 92% !important;
  }
  .Benefits-paragraph {
    font-size: 24px;
  }
  .benefits-section {
    margin-left: -1%;
    margin-top: 5% !important;
  }
  .benefits-Heading {
    font-size: 22px !important;
    margin-top: -4%;
    margin-left: -6%;
    font-weight: 500 !important;
  }
  .benefits-Heading1 {
    font-size: 22px !important;
    margin-top: -4%;
    margin-left: -6%;
    font-weight: 500 !important;
  }
  .benefits-Heading2 {
    font-size: 22px !important;
    margin-top: -3%;
    margin-left: -7%;
    font-weight: 500 !important;
  }
  .benefits-Heading3 {
    font-size: 22px !important;
    margin-top: -3%;
    margin-left: -7%;
    font-weight: 500 !important;
  }
  .benefits-Heading4 {
    font-size: 22px !important;
    margin-top: -1%;
    margin-left: -9%;
    font-weight: 500 !important;
  }
  .benefits-Heading5 {
    font-size: 22px !important;
    margin-top: -8%;
    margin-left: -4%;
    font-weight: 500 !important;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .leftContainer {
    width: 99% !important;
  }
  .Benefits-paragraph {
    font-size: 24px;
  }
  .benefits-section {
    margin-left: -1%;
    margin-top: 5% !important;
  }
  .benefits-Heading {
    font-size: 22px !important;
    margin-top: -4%;
    margin-left: 15%;
    font-weight: 500 !important;
  }
  .benefits-Heading1 {
    font-size: 22px !important;
    margin-top: -9%;
    margin-left: 14%;
    font-weight: 500 !important;
  }
  .benefits-Heading2 {
    font-size: 22px !important;
    margin-top: 0%;
    margin-left: 14%;
    font-weight: 500 !important;
  }

  .benefits-Heading3 {
    font-size: 22px !important;
    margin-top: -1%;
    margin-left: 13%;
    font-weight: 500 !important;
  }
  .benefits-Heading4 {
    font-size: 22px !important;
    margin-top: -3%;
    margin-left: 12%;
    font-weight: 500 !important;
  }
  .benefits-Heading5 {
    font-size: 22px !important;
    margin-top: -4%;
    margin-left: 13%;
    font-weight: 500 !important;
  }
  .icon-qg7 {
    max-height: 90px !important;
    max-width: 90px !important;
    min-height: 96px !important;
    min-width: 96px !important;
  }
}
@media (min-width: 992px) and (max-width: 1024px) {
  .leftContainer {
    width: 99% !important;
  }
  .Benefits-paragraph {
    font-size: 24px;
  }
  .benefits-section {
    margin-left: -1%;
    margin-top: 5% !important;
  }
  .benefits-Heading {
    font-size: 22px !important;
    margin-top: 8%;
    margin-left: -6%;
    font-weight: 500 !important;
  }
  .benefits-Heading1 {
    font-size: 22px !important;
    margin-top: 1%;
    margin-left: -6%;
    font-weight: 500 !important;
  }
  .benefits-Heading2 {
    font-size: 22px !important;
    margin-top: 4%;
    margin-left: -7%;
    font-weight: 500 !important;
  }
  .benefits-Heading3 {
    font-size: 22px !important;
    margin-top: 6%;
    margin-left: -7%;
    font-weight: 500 !important;
  }
  .benefits-Heading4 {
    font-size: 22px !important;
    margin-top: 6%;
    margin-left: -9%;
    font-weight: 500 !important;
  }
  .benefits-Heading5 {
    font-size: 22px !important;
    margin-top: 5%;
    margin-left: -4%;
    font-weight: 500 !important;
  }
  .icon-qg7 {
    max-height: 90px !important;
    max-width: 90px !important;
    min-height: 96px !important;
    min-width: 96px !important;
  }
}
